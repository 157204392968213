import { Form } from '@unform/web';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useLanguage } from '../../../../../../hooks/Language';
import CreditCardForm from './modules/CreditCard';
import GeneralForm from './modules/GeneralForm';
import CurrencyInput from '../../../../../../components/Forms/MaskedCurrencyInput';
import CompleteSaleFields from '../../../CompleteSaleFields';
import { FiArrowRight, FiTarget } from 'react-icons/fi';
import calculateDiscount from '../../Functions/calculateDiscount';
import Input from '../../../../../DashboardPages/core/components/Forms/Input';
import MaskedInput from '../../../../../DashboardPages/core/components/Forms/MaskedInput';
import SelectSimple from '../../../../../../components/Forms/SelectSimple';
import { countries } from '../../../Login/countries';
import ReCAPTCHA from 'react-google-recaptcha';
import { recaptchaKey } from '../../../../../../config';
import getCep from '../../../../../../services/cep';
import { FormHandles } from '@unform/core';
import { FaCheckCircle, FaHandHolding, FaHandPointer, FaHandPointRight, FaPlus, FaPortrait } from 'react-icons/fa';
import { useModal } from '../../../../../../hooks/Modal';
import * as Yup from 'yup';
import getValidationErrors from '../../../../../../utils/getValidationErrors';
import { useLoading } from '../../../../../../hooks/LoadingHook';
import { useToast } from '../../../../../../hooks/Toast';
import DefaultSignUpFormV4 from '../../../../../DefaultSignUpFormV4';
interface IProps {
    ticketList: Array<Record<string, any>>;
    applyTicketInfo: Function;
}



interface IPropsTickets {
    index: number;
    code: string;
    setCallback: Function;
    tickets: Array<Record<string, any>>;
    position: number;
}

interface IFormModal {
    ticket: Record<string, any>;
    setCallback?: Function;
}

const TicketFormModal: React.FC<IFormModal> = ({ ticket, setCallback = (item) => { } }) => {
    const formRef = useRef<FormHandles>(null);
    const [checkUser, setCheckUser] = useState('');
    const { addModal, removeModal } = useModal();
    const { addLoading, removeLoading } = useLoading();
    const [countryISO, setCountryISO] = useState('BR');
    const { translate } = useLanguage();
    const { addToast } = useToast();

    const validate = async (data) => {
        addLoading({ title: 'validando', key: 'validando' });
        console.log(checkUser)
        try {
            formRef.current?.setErrors({});


            const schema = checkUser === 'yes' ? Yup.object().shape({

                document_number: Yup.string()
                    .min(11, `${translate('CPF')} ${translate('obrigatório')}`)
                    .required(`${translate('CPF')} ${translate('obrigatório')}`),



            }) : Yup.object().shape({
                name: Yup.string().required(`${translate('Nome')} ${translate('obrigatório')}`),
                email: Yup.string().email().required(`${translate('E-mail')} ${translate('obrigatório')}`),
                phone: Yup.string().required(`${translate('Telefone')} ${translate('obrigatório')}`),
                document_number: Yup.string()
                    .min(11, `${translate('CPF')} ${translate('obrigatório')}`)
                    .required(`${translate('CPF')} ${translate('obrigatório')}`),



            });


            data.document_number = data?.document_number?.toString()?.replace(/[^0-9]/g, '');


            await schema.validate(data, { abortEarly: false });
            setCallback(data);
            removeLoading('validando');
            removeModal('modal');
        }
        catch (err) {
            if (err instanceof Yup.ValidationError) {
                const errors = getValidationErrors(err);

                formRef.current?.setErrors(errors);

                const errorInfo = err.errors.reduce((error, prev) => {
                    prev = `${prev}, ${error}`;
                    return prev;
                });
                removeLoading('validando')
                addToast({
                    type: 'error',
                    title: 'Erro de validação',
                    description: errorInfo,
                });
            }
            removeLoading('validando')
        }




    }

    return <Form key={ticket?.code} className="form"
        ref={formRef}
        initialData={{ country: "BR", ...ticket?.userData }}
        onSubmit={(data) => validate(data)}

    >
        <h2 style={{ color: '#333', width: '100%', textAlign: 'center' }}>Dados do participante</h2>



        <DefaultSignUpFormV4 checkUserValue={(value) => setCheckUser(value)} countryISO={'BR'} changeCountry={(value) => { }} />



    </Form>



}


const TicketFormContainer: React.FC<IPropsTickets> = ({ index, code, setCallback, tickets, position }) => {
    const formRef = useRef<FormHandles>(null);

    const ticket = tickets?.find(i => i?.code === code) || {};
    const [countryISO, setCountryISO] = useState('BR');

    const [company, setCompany] = useState('');
    const [active, setActive] = useState(false);
    const { addModal, removeModal } = useModal();
    const { addLoading, removeLoading } = useLoading();
    const { addToast } = useToast();
    const [checkUser, setCheckUser] = useState('');

    const { language, translate } = useLanguage();


    const validateToMe = async () => {
        addLoading({ title: 'validando', key: 'validando' });

        /*  const index = tickets?.findIndex(i => i?.userData?.type === 'self');
 
         if (index >= 0) {
             removeLoading('validando');
             removeModal('modal');
             addToast({ title: 'Você só pode atribuir 1 ingresso ao seu cadastro.', type: 'error' });
             return;
         } */
        removeLoading('validando');
        removeModal('modal');
        setCallback({ type: 'self' });


    }


    const openModalCheck = () => {

        addModal({
            key: 'modal',
            theme: 'whiteModalMedium',
            title: '',
            content: <>

                <h2 style={{ color: '#333', width: '100%', textAlign: 'center' }}>{translate('Cadastrar ingresso')}</h2>
                <aside style={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
                    <button onClick={() => validateToMe()} className='greenButton2 buttonColored'>{translate('Para o meu cadastro')}</button>
                </aside >

                <h2 style={{ color: '#333', width: '100%', textAlign: 'center' }}>{translate('OU')}</h2>

                <aside style={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
                    <button onClick={() => openModal()} className='greenButton2 buttonColored'>{translate('Para outro participante')}</button>
                </aside >
            </>
        })



    }

    const openModal = () => {

        addModal({
            key: 'modal',
            theme: 'whiteModalMedium',
            title: '',
            content: <TicketFormModal setCallback={setCallback} ticket={ticket} />
        })


    }



    return <> <div className='row scaleOnHover ' style={{ borderRadius: '5px', border: '1px solid #ddd', padding: '5px 0px', cursor: 'pointer', width: '100%', justifyContent: 'flex-start', alignItems: 'stretch' }}
        onClick={() => openModalCheck()}>
        <div style={{ width: '40px', borderRight: '2px dashed #333', marginRight: '10px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <FaPortrait style={{ color: 'rgb(0,102,178)', fontSize: '24px' }} />
        </div>

        <div className='column full-width'><h2 style={{ fontSize: '14px', margin: ' 0px', width: '100%', color: '#333', padding: '0px', textAlign: 'left' }}>{ticket?.product?.[`title_${language}`] ? ticket?.product?.[`title_${language}`] : ticket?.product?.title} </h2>

            <p style={{ fontSize: '14px', margin: ' 0px', width: '100%', color: '#333', padding: '0px', textAlign: 'left' }}> {ticket?.userData?.type === 'self' ? translate('Atribuido ao seu cadastro') : ticket?.userData?.document_number ? `Documento: ${ticket?.userData?.document_number}` : 'Adicionar participante'}  </p>
        </div>
        <div style={{ width: '40px', borderLeft: '2px dashed #333', marginLeft: '10px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            {ticket?.validated === true ? <FaCheckCircle style={{ color: 'green' }} /> : <FaPlus />}
        </div>

    </div>


    </>
}


const TicketForm: React.FC<IProps> = ({ ticketList, applyTicketInfo }) => {



    return <>
        <p style={{ color: '#333', fontSize: '12px', margin: '5px 0px' }}><span style={{ color: "rgb(0,102,178)", fontWeight: 'bold' }}><strong>Clique no ingresso</strong></span> para alterar o convidado</p>
        {ticketList?.map((item, index) => {

            return <TicketFormContainer code={item?.code} position={item?.position} index={index} tickets={ticketList} setCallback={(data) => applyTicketInfo(item?.code, data, ticketList, item?.position)} />


        })}

    </>

};
export default TicketForm;
