import React from 'react';
import { Link } from 'react-router-dom';
import ContagemRegressiva from 'src/components/ContagemRegressiva';
import ImageGallery from 'src/components/ImageGallery';
import VideoGallery from 'src/components/VideoGallery';
import { urlWebsite } from 'src/config';
import EventsPages from 'src/PagesApplication/HomeApplication/modules/Paginas';
import IframePlayer from '../../../../../../components/IframePlayer';
import IframeWidget from '../widgets/IframeWidget';
import { IContentItem } from '../../hooks/PageCreatorContext';
import { ButtonStyle, EditableDiv } from '../../styles';
import GridList from '../widgets/GridList';
import SponsorsWidgets from '../widgets/Sponsors';
import TableWidget from '../widgets/Table';
import LinkListWidget from '../widgets/LinkListWidget';
import { ImageStyle, ParagraphStyle, SubTitleStyle, TitleStyle, VideoStyle } from './styles';
import Contact from '../widgets/Contact';
import ContactList from '../widgets/ContactList';
import SpeakerGrid, { SpeakerElement } from 'src/components/SpeakersGrid';
import IframeListItem from '../widgets/components/IframeListItem';
import SocialWidget from '../widgets/Social';
import ProgramList from '../widgets/ProgramList';

interface IContentItemProps {
    contentItem: IContentItem;
    editable?: boolean;
    editContent?: Function;
    context: Record<string, any>;
    language: string;
}

const ContentItem: React.FC<IContentItemProps> = ({ language, context = {}, editContent = () => { }, editable = false, contentItem }) => {


    const content = contentItem?.[`value_${language}`] ? contentItem?.[`value_${language}`] : contentItem?.value;
    const computerStyle = contentItem?.style?.computer || {};
    const mobileStyle = contentItem?.style?.mobile || {};

    switch (contentItem.type) {
        case 'title':
            return <TitleStyle onClick={() => editable ? editContent() : false} computer={computerStyle} mobile={mobileStyle}>{content || 'Adicione um Título'}</TitleStyle>;
        case 'subtitle':
            return <SubTitleStyle onClick={() => editable ? editContent() : false} computer={computerStyle} mobile={mobileStyle} >{content || 'Adicione um Subtítulo'}</SubTitleStyle>;

        case 'text':
            return <ParagraphStyle onClick={() => editable ? editContent() : false} dangerouslySetInnerHTML={{ __html: content || '<p>Adicione um texto </p>' }} computer={computerStyle} mobile={mobileStyle} />;

        case 'image':
            return <ImageStyle onClick={() => editable ? editContent() : false} computer={computerStyle} mobile={mobileStyle} src={`${urlWebsite}/${content || '/apoio/image.jpeg'}`} alt="" />;
        case 'video':
            return <VideoStyle onClick={() => editable ? editContent() : false} computer={computerStyle} mobile={mobileStyle}><IframePlayer id={content} /></VideoStyle>;
        case 'video-gallery':
            return (
                <div className='full-width' onClick={() => editable ? editContent() : false}> <VideoGallery data={content} /></div>
            );
        case 'link-list':
            return (
                <div className='full-width' onClick={() => editable ? editContent() : false}><LinkListWidget data={content} /></div>
            );
        case 'iframe':
            return (
                <div className='full-width' onClick={() => editable ? editContent() : false}><IframeWidget data={content} computer={computerStyle} mobile={mobileStyle} /></div>
            );
        case 'image-gallery':
            return (
                <div className='full-width' onClick={() => editable ? editContent() : false}><ImageGallery data={content} /></div>
            );
        case 'speaker-grid':
            return (
                <div className='full-width column' onClick={() => editable ? editContent() : false}>
                    {content?.map(speakers => {

                        return <div className='full-width column' >
                            {speakers?.title ? <TitleStyle computer={{ ...computerStyle, 'margin-bottom': '15px', 'margin-top': '30px' }} mobile={mobileStyle}>{speakers?.title || ''}</TitleStyle> : <></>}
                            {speakers?.subtitle ? <ParagraphStyle dangerouslySetInnerHTML={{ __html: speakers?.subtitle || '<p></p>' }} computer={{ color: '#999', width: '100%', textAlign: 'center', fontSize: '16px', ...computerStyle }} mobile={mobileStyle} /> : <></>}
                            <div className='  gap full-width' style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'center', flexWrap: 'wrap' }}>
                                {speakers?.speakers?.map(speaker => <SpeakerElement theme={{ speaker_title_color: contentItem?.style?.computer?.color || '#333' }} speaker={speaker} />)}

                            </div>

                        </div>



                    })
                    }

                </div >

            );

        case 'video_list':
            return (
                <div className='full-width column' onClick={() => editable ? editContent() : false}>
                    {content?.map(videos => {

                        return <div className='full-width column' >
                            {videos?.title ? <SubTitleStyle computer={{ ...computerStyle, 'margin-bottom': '0px', 'margin-top': '30px' }} mobile={mobileStyle}>{videos?.title || ''}</SubTitleStyle> : <></>}
                            {videos?.subtitle ? <ParagraphStyle dangerouslySetInnerHTML={{ __html: videos?.subtitle || '<p></p>' }} computer={{ color: '#999', width: '100%', textAlign: 'center', fontSize: '16px', ...computerStyle }} mobile={mobileStyle} /> : <></>};
                            <div className='  column-start full-width' style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'flex-start', }}>
                                {videos?.videos?.map(item => <IframeListItem theme={{ color: content?.style?.computer || '#333' }} item={item} />)}

                            </div>

                        </div>



                    })
                    }

                </div >

            );
        case 'grid-content':
            return (
                <div className='full-width' onClick={() => editable ? editContent() : false}><GridList list={content || []} /></div>
            );
        case 'contact':
            return (
                <div className='full-width' onClick={() => editable ? editContent() : false}><Contact data={content?.[0] || {}} /></div>
            );

        case 'contact-list':
            return (
                <div className='full-width' onClick={() => editable ? editContent() : false}><ContactList data={content} /></div>
            );
        case 'sponsors':
            return (
                <div className='full-width' onClick={() => editable ? editContent() : false}><SponsorsWidgets data={content} /></div>
            );

        case 'table':
            return (
                <div className='full-width' onClick={() => editable ? editContent() : false}>{<TableWidget data={content} computer={computerStyle} mobile={mobileStyle} />}</div>
            );
        case 'buttons':
            return <EditableDiv style={{ gap: '10px' }} computer={computerStyle} mobile={mobileStyle} onClick={() => editable ? editContent() : false}>
                {content?.map(buttonInfo => buttonInfo?.type === 'external' ? <a onClick={() => { window?.scrollTo(0, 0) }} href={buttonInfo?.url} target="_blank"><ButtonStyle style={{ margin: '0px' }} className='greenButton2 shadow' computer={computerStyle} mobile={mobileStyle}>{buttonInfo?.title}</ButtonStyle></a> : <a onClick={() => { window?.scrollTo(0, 0) }} href={buttonInfo?.url}><ButtonStyle style={{ margin: '0px' }} className='greenButton2 shadow' computer={computerStyle} mobile={mobileStyle}>{buttonInfo?.title}</ButtonStyle></a>)}
            </EditableDiv>
        case 'programlist':
            return <EditableDiv style={{ gap: '10px' }} computer={computerStyle} mobile={mobileStyle} onClick={() => editable ? editContent() : false}>
                <ProgramList data={content} computer={computerStyle} mobile={mobileStyle} />
            </EditableDiv>
        case 'social':
            return <EditableDiv style={{ gap: '10px' }} computer={computerStyle} mobile={mobileStyle} onClick={() => editable ? editContent() : false}>
                <SocialWidget computer={computerStyle} mobile={mobileStyle} data={content} />
            </EditableDiv>
        case 'counter':
            return <div style={{ width: 'auto' }} onClick={() => editable ? editContent() : false}><ContagemRegressiva dataDesejada={content || '2020-10-12T09:00:00-03:00'} /></div>

        case 'custom':
            return <div className="content-item">Custom Component: {content?.name}</div>;
        case 'landingpage':
            return <EventsPages hash={content} project_id={content?.project_id} />
        default:
            return null;
    }
};

export default ContentItem;