import { useEffect, useState } from "react";
import { uuid } from "uuidv4";
import { useToast } from "../../../../../hooks/Toast";
import useFieldsList from "./salesFields/salesFields";



export default function useCart() {

    /* this function manage the cart */

    const [cart, setCart] = useState<Array<Record<string, any>>>([]);
    const [ticketsInfo, setTicketsInfo] = useState<Array<Record<string, any>>>([]);
    const { addToast } = useToast();
    const { checkFieldListValidation, fieldsListGetData } = useFieldsList();


    const applyTicketInfo = (code, data, ticketsInfoContent, position) => {

        const items = [...ticketsInfoContent];

        const index = items?.findIndex(i => i?.code === code && i?.position === position);






        items[index].userData = { ...data };
        items[index].validated = true;
        setTicketsInfo([...items])


    }

    const applyTicketFields = (code, data, ticketsInfoContent, position) => {

        const items = [...ticketsInfoContent];
        const index = items?.findIndex(i => i?.code === code && i?.position === position);
        const { fields, fields_text } = fieldsListGetData({ product: items[index]?.product || {}, data });



        items[index].fields = fields;
        items[index].fields_text = fields_text;

        setTicketsInfo([...items])




    }

    const changeCart = (product, option, cartCurrentValue, ticketsInfoData, groupClear = '') => {



        let cartItems = [...cartCurrentValue];

        if (groupClear) {
            cartItems = cartItems.filter(i => i?.group_name !== groupClear)

            ticketsInfoData = ticketsInfoData?.filter(i => i?.product?.group !== groupClear)

        }

        const index = cartItems?.findIndex(item => item?._id?.toString() === product?._id?.toString());

        if (option === 'add') {

            const group = uuid();

            if (((cartItems?.[index]?.quantity || 0) + 1) > product?.available_quantities) {
                addToast({ title: 'Quantidade indisponível', type: 'info' });
                return;
            }

            if (index >= 0) {
                cartItems[index].quantity = cartItems[index].quantity + 1;

                cartItems[index].group.push(group);
            }
            else {
                cartItems.push({ ...product, group: [group], group_name: product?.group || '', quantity: 1 });
            }

            const ticketsData: Record<string, any>[] = [];

            let x = 1;
            const position = product?.quantity_reference || 1;
            while (x <= position) {

                ticketsData.push({
                    product: { ...product, quantity: 1 },
                    userData: { type: 'self' },
                    code: uuid(),
                    position,
                    validated: true,
                    group

                })
                x += 1;
            }

            setTicketsInfo([...ticketsInfoData, ...ticketsData])


        }
        else if (option === 'remove') {

            let group = cartItems?.[index]?.group?.pop();

            if (index >= 0) {
                if (cartItems[index].quantity === 1) {
                    cartItems.splice(index, 1);
                }
                else {
                    cartItems[index].quantity = cartItems[index].quantity - 1;

                }

            }



            const indexTicket = ticketsInfoData?.findIndex(p => p?.product?._id?.toString() === product?._id?.toString());



            const newtickets = ticketsInfoData?.filter(ticket => ticket?.group !== group)


            setTicketsInfo(newtickets);



        }
        setCart(cartItems)



    }

    return { cart, changeCart, setCart, ticketsInfo, applyTicketInfo, applyTicketFields }

}