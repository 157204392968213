import React, {
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { IconBaseProps } from 'react-icons';

import { useField, FormHandles } from '@unform/core';
import { Container, Field } from './styles';
import { Error } from '../styles';
import { FaChevronDown, FaChevronUp, FaEdit, FaPlus, FaTrash } from 'react-icons/fa';
import { uuid } from 'uuidv4';
import { Form } from '@unform/web';
import Input from '../Input';
import SelectSimple from '../SelectSimple';
import RichText from '../RichText';
import { useModal } from '../../../../../../hooks/Modal';
import JsonList from '../JsonListMultiple';
import ArrayIncluder from '../ArrayInputIncluder';
import MaskedCurrencyInput from '../MaskedCurrencyInput';
import ImageSingleGalleryInput from '../ImageSingleGalleryInput';

interface IOptions {
  label: string;
  value: string;
  text?: string;

}

interface IListInput {
  label: string;
  name: string;
  type?: string;
  list?: IListInput[];
  options?: IOptions[];
  show?: Array<string> | string;
}

interface IInputProps {
  name: string;
  icon?: React.ComponentType<IconBaseProps>;
  setCallback?: Function;
  formRef?: React.RefObject<FormHandles>;
  list: Array<IListInput>;
  defaultItem?: Array<Record<string, any>>;
  placeholder: string;
  show?: Array<string> | string;
  keyName?: string;
}

/*
The concept is simple.
Create a form with multiple inputs

*/

const JsonInputList: React.FC<IInputProps> = ({
  name,
  icon: Icon,
  formRef,
  setCallback = () => { },
  placeholder,
  defaultItem,
  list,
  show = [],
  keyName = 'jsonList',
  ...rest
}): JSX.Element => {

  const { addModal, removeModal } = useModal();

  const { fieldName, defaultValue, error, registerField } = useField(name);
  const inputRef = useRef<HTMLInputElement>(null);

  const [value, setValue] = useState(defaultValue || defaultItem || []);



  useEffect(() => {


    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: undefined,
      setValue: (ref: any, value: any) => {
        setValue(value);
      },
      getValue: (ref: any) => {
        return value;
      },
    });
  }, [fieldName, registerField, value]);


  const goUp = index => {
    const indexup = index - 1;

    if (value[indexup]) {
      const newCurrent = [...value];

      newCurrent[index] = value[indexup];
      newCurrent[indexup] = value[index];

      setValue([...newCurrent]);

    }
  };

  const goDown = index => {
    const indexup = index + 1;

    if (value[indexup]) {
      const newCurrent = [...value];

      newCurrent[index] = value[indexup];
      newCurrent[indexup] = value[index];

      setValue([...newCurrent]);
    }
  };

  const defaultValueList = {
    input: '',
    select: '',
    textList: '',
    jsonList: [],
    arrayIncluder: []
  }

  const addRow = () => {
    const newValue = [...value];

    console.log(list)

    const newLine = Object.keys(list).reduce((prev, key) => {
      prev[list[key].name] = defaultValueList[list?.[key]?.type || 'input'];
      return prev;
    }, {});

    newValue.push(newLine);

    setValue([...newValue]);
  };

  const removeItem = (index) => {

    const item = [...value];
    item.splice(index, 1);

    setValue([...item]);

  }

  const printLines = useCallback(() => {

    const items: Array<JSX.Element> = [];
    {
      value.map((v, index) => {

        const key = uuid();
        items.push(<div key={key} className='display'>
          <p>{v.title || 'Adicione sua pergunta'}</p>
          <div className='listModules'>
            <div><FaEdit onClick={() => openInputs(index)} size={15} style={{ cursor: 'pointer' }} /></div>

            <div><FaChevronUp onClick={() => goUp(index)} style={{ cursor: 'pointer' }} /></div>
            <div><FaChevronDown onClick={() => goDown(index)} style={{ cursor: 'pointer' }} /></div>
            <div><FaTrash onClick={() => removeItem(index)} style={{ cursor: 'pointer' }} /></div>
          </div>
        </div>)

      })

      return items;

    }
  }, [value]);


  const validTypes = (listInput) => {

    const valid = {
      currency: (item) => <MaskedCurrencyInput name={item.name} placeholder={item.label} />,
      image: (item) => <ImageSingleGalleryInput list={[{ name: 'url' }]} name={item.name} placeholder={item.label} />,
      input: (item) => <Input name={item.name} placeholder={item.label} />,
      select: (item) => <SelectSimple name={item.name} label={item.label} options={item.options} />,
      richText: (item) => <RichText name={item.name} placeholder={item.label} />,
      jsonList: (item) => <JsonInputList keyName='sub-update-form' list={item.list} show={item?.show ? item?.show : ['title']} name={item.name} placeholder={item.label} />,
      jsonInputList: (item) => <JsonInputList keyName='sub-update-form' list={item.list} show={item?.show ? item?.show : ['title']} name={item.name} placeholder={item.label} />,
      arrayIncluder: (item) => <ArrayIncluder name={item.name} placeholder={item.label} />
    }

    return valid[listInput.type] ? valid[listInput.type](listInput) : <></>;

  }

  const openInputs = (index) => {

    const updateItem = (data) => {

      const valuesItems = [...value];
      valuesItems[index] = { ...data };

      setValue([...valuesItems]);
      removeModal(`updateForm-${name}`)

    }



    addModal({
      title: 'Atualizar', theme: "whiteModal", key: `updateForm-${name}`, content: <Form onSubmit={updateItem} initialData={{ ...value[index] }}>

        {list.map((item => {
          return validTypes(item);
        }))}

        <aside className='displayCenter'>
          <button className='defaultButton' style={{ width: '200px', margin: '10px auto' }}>Atualizar</button>
        </aside>

      </Form>
    }
    )

  }

  return (
    <div style={{ display: 'flex', flexDirection: 'column', padding: '20px 10px', border: '2px solid #eee' }}>
      <h2 style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>{placeholder}

        <div className="iconMod"> <FaPlus onClick={() => addRow()} size={15} style={{ margin: '0px 3px', cursor: 'pointer' }} /></div>
      </h2>

      <Container>{printLines()}</Container>
    </div>
  );
};

export default JsonInputList;
