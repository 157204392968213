import React, { useEffect, useState } from "react";
import axios from "axios";
import api from "src/services/api";
import IframePlayer from "src/components/IframePlayer";
import { dateWithHourBR } from "src/utils/date";
import { useLoading } from "src/hooks/LoadingHook";
import { useToast } from "src/hooks/Toast";


const Options = ({ quantity }) => {

  let x = 1;

  return <>


  </>



}

const BunnyVideoList = () => {
  const LIBRARY_ID = "269127"; // Insira o Library ID aqui
  const API_KEY = "55d0cf94-c7a0-4dc8-933f71ce71b8-6441-4ba1"; // Insira o API Key aqui
  const { addLoading, removeLoading } = useLoading();
  const { addToast } = useToast();
  const [pageQuantity, setPageQuantity] = useState(1);
  const [page, setPage] = useState(1);
  const [videos, setVideos] = useState([]);
  const [projects, setProjects] = useState<Array<Record<string, any>>>([]);
  const [events, setEvents] = useState<Array<Record<string, any>>>([]);
  const [lessons, setLessons] = useState([]);
  const [downloadList, setDownloadList] = useState<Array<any>>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState("");


  const loadProjects = async () => {
    const projectsList = await api.get('/projects', { params: { limitItems: 10000 } });
    const eventsList = await api.get('/events', { params: { limitItems: 10000 } });

    setProjects(projectsList?.data?.rows)
    setEvents(eventsList?.data?.rows)
  }

  const loadLessons = async () => {
    const lessonsList = await api.get('/lessons', { params: { limitItems: 10000 } });

    setLessons(lessonsList?.data?.rows)
  }


  // Função para listar vídeos
  const fetchVideos = async (pageNumber: number) => {
    setIsLoading(true);
    setError("");

    try {
      const url = `https://video.bunnycdn.com/library/${LIBRARY_ID}/videos?page=${pageNumber}&itemsPerPage=30`;
      const response = await axios.get(url, {
        headers: {
          Accept: "application/json",
          AccessKey: API_KEY,
        },
      });

      setVideos(response.data.items || []); // Salva os vídeos obtidos
      setPageQuantity(parseInt((response?.data?.totalItems / 30).toString(), 10))
    } catch (err) {
      console.error("Erro ao buscar vídeos:", err);
      setError("Erro ao buscar vídeos. Verifique a API Key e o Library ID.");
    } finally {
      setIsLoading(false);
    }
  };

  // Função para baixar um vídeo
  const downloadVideo = async (storageZone, videoId) => {
    // addLoading({ title: 'Download em andamento: Esta ação pode demorar muitos minutos', key: 'loading' })

    addToast({ title: 'Download Iniciado', type: 'info' })
    setDownloadList(state => [...state, videoId])
    try {


      const url = ``;
      const response = await axios.get(url, {
        headers: {
          Accept: "application/json",
          AccessKey: 'ee975965-6fa7-42d2-bcbdb3d44e53-0cf0-4498',
        },
        responseType: "blob", // Necessário para download de arquivo
      });

      // Criar link de download
      const blob = new Blob([response.data], { type: "application/zip" });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = `${videoId}.zip`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      addToast({ title: 'Download Finalizado', type: 'info' })

      setDownloadList(state => state.find(i => i !== videoId))
    } catch (err) {
      console.error("Erro ao baixar o vídeo:", err);
      addToast({ title: 'Erro ao realizar download', type: 'error' })
      setDownloadList(state => state.find(i => i !== videoId))
    }
  };

  // Carregar vídeos ao montar o componente
  useEffect(() => {

    fetchVideos(page);
  }, [page]);

  useEffect(() => {
    loadLessons();
    loadProjects();
  }, [])

  const checkIn = (item, lessons) => {

    const es = lessons.find(i => i?.languages?.es?.indexOf(item?.guid) >= 0);

    if (es) {
      return { lesson: es, language: 'Espanhol' }
    }

    const en = lessons.find(i => i?.languages?.en?.indexOf(item?.guid) >= 0);

    if (en) {
      return { lesson: en, language: 'Inglês' }
    }

    const pt = lessons.find(i => i?.video_id.indexOf(item?.guid) >= 0);

    if (pt) {
      return { lesson: pt, language: 'Português' }
    }

    return { lesson: item, language: 'Indefinido' }

  }

  return (



    <div style={{ padding: "20px", maxWidth: "1200px", margin: "0 auto" }}>
      <div className="row gap pad"><h2>Lista de Vídeos</h2>
        <select style={{ padding: '10px', borderRadius: '10px', background: '#fff' }} onChange={(e) => setPage(parseInt(e?.target?.value, 10))}>


          {Array.from({ length: pageQuantity }, (_, x) => (
            <option key={x + 1} selected={(x + 1) === page} value={x + 1}>
              Página {x + 1}
            </option>
          ))}

        </select>
      </div>

      {isLoading ? (
        <p>Carregando vídeos...</p>
      ) : error ? (
        <p style={{ color: "red" }}>{error}</p>
      ) : videos.length === 0 ? (
        <p>Nenhum vídeo encontrado.</p>
      ) : (

        <>

          <div style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'center', gap: '20px', flexWrap: 'wrap' }}>
            {videos.map((video: Record<string, any>) => {

              const { lesson, language } = checkIn(video, lessons);
              const project = projects?.find(i => i?.url === lesson?.project_id)

              return < div
                key={video.guid}
                style={{
                  width: '300px',
                  padding: "10px",
                  background: '#fff',
                  border: "1px solid #ddd",
                  borderRadius: "15px",
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  gap: '10px'
                }}
              >
                <img style={{ width: '250px' }} src={`https://vz-ed46b79b-79c.b-cdn.net/${video?.guid}/${video?.thumbnailFileName}`} />

                <h4 style={{ fontSize: '14px', color: '#333', textAlign: 'center' }}>{project?.title}</h4>
                <h4 style={{ fontSize: '14px', color: '#333', textAlign: 'center' }}>{lesson.title || video.title}</h4>
                <p style={{ fontSize: '14px', color: '#333', textAlign: 'center' }}>
                  <strong>Data:</strong>{" "}
                  {lesson?.release_date ? dateWithHourBR(lesson?.release_date) : new Date(video.dateUploaded).toLocaleDateString()}
                </p>
                <a href={`https://storage.bunnycdn.com/vz-ed46b79b-79c/${video.guid}/?accessKey=ee975965-6fa7-42d2-bcbdb3d44e53-0cf0-4498&download`} target="_BLANK">  <button

                  style={{
                    padding: "8px 16px",
                    background: downloadList?.findIndex(i => i === video?.guid) >= 0 ? 'green' : "#007bff",
                    color: "white",
                    border: "none",
                    borderRadius: "5px",
                    cursor: "pointer",
                  }}
                >
                  {downloadList?.findIndex(i => i === video?.guid) >= 0 ? 'Realizando download' : 'Baixar Vídeo'}
                </button>
                </a>
              </div>
            })}
          </div>
        </>
      )
      }
    </div >
  );
};

export default BunnyVideoList;
