import { format, parseISO, addHours, getHours } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import pt from 'date-fns/locale/pt-BR/index.js';
import es from 'date-fns/locale/es/index.js';
import en from 'date-fns/locale/en-US/index.js';


export const toGMT3 = (date) => {
  const localOffset = date.getTimezoneOffset(); // Obtém o offset local em minutos
  const gmt3Offset = -3 * 60; // Offset para GMT -3 em minutos
  const offsetDiff = gmt3Offset - localOffset; // Diferença entre o fuso atual e GMT -3
  return new Date(date.getTime() + offsetDiff * 60 * 1000); // Ajusta a data
};

export const checkIsAfterDate = (currentDate: string | Date, compareToDate = new Date()) => {
  try {
    const fromDate = typeof currentDate === 'object' ? toGMT3(new Date(currentDate)) : typeof currentDate === 'string' ? toGMT3(new Date(currentDate)) : toGMT3(currentDate);
    const toDate = typeof compareToDate === 'object' ? toGMT3(new Date(compareToDate)) : typeof compareToDate === 'string' ? toGMT3(new Date(compareToDate)) : toGMT3(compareToDate);

    return fromDate > toDate;
  }
  catch (err) {

    return false;
  }

}
export const dateWithHourUS = (dateValue) => {
  if (!dateValue) {
    return '';
  }

  let formattedDate = '';
  try {
    const parsedDate =
      typeof dateValue === 'object' ? new Date(dateValue) : parseISO(dateValue);

    const zonedDate = utcToZonedTime(parsedDate, 'America/Sao_Paulo');
    formattedDate = format(zonedDate, "MM/dd/yyyy HH':'mm", { locale: en });
  } catch (err) {
    return '';
  }

  return formattedDate;
};

export const dateWithHourES = (dateValue) => {
  if (!dateValue) {
    return '';
  }

  let formattedDate = '';
  try {
    const parsedDate =
      typeof dateValue === 'object' ? new Date(dateValue) : parseISO(dateValue);

    const zonedDate = utcToZonedTime(parsedDate, 'America/Sao_Paulo');
    formattedDate = format(zonedDate, "dd/MM/yyyy HH':'mm", { locale: es });
  } catch (err) {
    return '';
  }

  return formattedDate;
};
export const dateWithHourBR = (dateValue) => {
  if (!dateValue) {
    return '';
  }
  let formattedDate = '';
  try {
    const parsedDate =
      typeof dateValue === 'object' ? new Date(dateValue) : parseISO(dateValue);

    const zonedDate = utcToZonedTime(dateValue, 'America/Sao_Paulo');
    formattedDate = format(zonedDate, "dd/MM/yyyy HH'h'mm", { locale: pt });
  } catch (err) {
    return '';
  }
  return formattedDate;
};

export const dateLanguageDate = (dateValue: string | Date, language = 'ptBr'): string => {
  if (!dateValue) {
    return '';
  }
  let formattedDate = '';
  try {
    const parsedDate =
      typeof dateValue === 'object' ? new Date(dateValue) : parseISO(dateValue);
    const zonedDate = utcToZonedTime(parsedDate, 'America/Sao_Paulo');


    formattedDate = format(zonedDate, "dd 'de' MMMM 'de' yyyy", {
      locale: pt,
    });

  } catch (err) {
    console.log(err)
    return '';
  }

  formattedDate = formattedDate.replace('março', 'Março');
  formattedDate = formattedDate.replace('abril', 'Abril');
  formattedDate = formattedDate.replace('maio', 'Maio');
  formattedDate = formattedDate.replace('junho', 'Junho');
  formattedDate = formattedDate.replace('julho', 'Julho');
  formattedDate = formattedDate.replace('agosto', 'Agosto');
  formattedDate = formattedDate.replace('setembro', 'Setembro');
  formattedDate = formattedDate.replace('outubro', 'Outubro');
  formattedDate = formattedDate.replace('novembro', 'Novembro');
  formattedDate = formattedDate.replace('dezembro', 'Dezembro');
  formattedDate = formattedDate.replace('janeiro', 'Janeiro');
  formattedDate = formattedDate.replace('fevereiro', 'Fevereiro');

  formattedDate = formattedDate.replace('segunda', 'Segunda');
  formattedDate = formattedDate.replace('terça', 'Terça');
  formattedDate = formattedDate.replace('quarta', 'Quarta');
  formattedDate = formattedDate.replace('quinta', 'Quinta');
  formattedDate = formattedDate.replace('sexta', 'Sexta');
  formattedDate = formattedDate.replace('sábado', 'Sábado');
  formattedDate = formattedDate.replace('domingo', 'Domingo');

  return formattedDate;
};
export const dateFullWithHour = (dateValue: string | Date, language = 'ptBr'): string => {

  if (!dateValue) {
    return '';
  }
  let formattedDate = '';
  try {
    const parsedDate =
      typeof dateValue === 'object' ? new Date(dateValue) : parseISO(dateValue);
    const zonedDate = utcToZonedTime(parsedDate, 'America/Sao_Paulo');



    formattedDate = format(zonedDate, "dd 'de' MMMM 'de' yyyy 'as' HH'h'mm ", {
      locale: pt,
    });
    console.log(formattedDate);
  } catch (err) {
    console.log(err);
    return '';
  }

  formattedDate = formattedDate.replace('março', 'Março');
  formattedDate = formattedDate.replace('abril', 'Abril');
  formattedDate = formattedDate.replace('maio', 'Maio');
  formattedDate = formattedDate.replace('junho', 'Junho');
  formattedDate = formattedDate.replace('julho', 'Julho');
  formattedDate = formattedDate.replace('agosto', 'Agosto');
  formattedDate = formattedDate.replace('setembro', 'Setembro');
  formattedDate = formattedDate.replace('outubro', 'Outubro');
  formattedDate = formattedDate.replace('novembro', 'Novembro');
  formattedDate = formattedDate.replace('dezembro', 'Dezembro');
  formattedDate = formattedDate.replace('janeiro', 'Janeiro');
  formattedDate = formattedDate.replace('fevereiro', 'Fevereiro');

  formattedDate = formattedDate.replace('segunda', 'Segunda');
  formattedDate = formattedDate.replace('terça', 'Terça');
  formattedDate = formattedDate.replace('quarta', 'Quarta');
  formattedDate = formattedDate.replace('quinta', 'Quinta');
  formattedDate = formattedDate.replace('sexta', 'Sexta');
  formattedDate = formattedDate.replace('sábado', 'Sábado');
  formattedDate = formattedDate.replace('domingo', 'Domingo');

  return formattedDate;
};


export const date = (dateValue: string): string => {
  if (!dateValue) {
    return '';
  }
  let formattedDate = '';
  try {
    const parsedDate =
      typeof dateValue === 'object' ? new Date(dateValue) : parseISO(dateValue);

    const zonedDate = utcToZonedTime(dateValue, 'America/Sao_Paulo');
    formattedDate = format(zonedDate, 'dd/MM/yyyy', { locale: pt });
  } catch (err) {
    return '';
  }
  return formattedDate;
};

export const dateWithHour = (dateValue: string): string => {
  if (!dateValue) {
    return '';
  }
  let formattedDate = '';
  try {
    const parsedDate =
      typeof dateValue === 'object' ? new Date(dateValue) : parseISO(dateValue);

    const zonedDate = utcToZonedTime(dateValue, 'America/Sao_Paulo');
    formattedDate = format(zonedDate, "dd/MM/yyyy HH'h'mm", { locale: pt });
  } catch (err) {
    return '';
  }
  return formattedDate;
};

export const simpleDotDate = (dateValue: string, language = 'ptBr'): string => {
  let formattedDate = '';
  try {
    const parsedDate =
      typeof dateValue === 'object' ? new Date(dateValue) : parseISO(dateValue);
    const zonedDate = utcToZonedTime(parsedDate, 'America/Sao_Paulo');
    formattedDate = format(zonedDate, language === 'en' ? 'MM.dd' : 'dd.MM', { locale: pt });
  } catch (err) {
    return '';
  }
  return formattedDate;
};

export const dateSimple = (dateValue: string): string => {
  let formattedDate = '';
  try {
    const parsedDate =
      typeof dateValue === 'object' ? new Date(dateValue) : parseISO(dateValue);
    const zonedDate = utcToZonedTime(parsedDate, 'America/Sao_Paulo');
    formattedDate = format(zonedDate, 'dd/MM', { locale: pt });
  } catch (err) {
    return '';
  }
  return formattedDate;
};

export const hour = (dateValue: string, language = 'ptBr'): string => {
  if (!dateValue) {
    return '';
  }
  let formattedDate = '';
  try {
    const parsedDate =
      typeof dateValue === 'object' ? new Date(dateValue) : parseISO(dateValue);


    const zonedDate = utcToZonedTime(parsedDate, 'America/Sao_Paulo');
    formattedDate = format(zonedDate, "HH'h'mm", { locale: pt });

    formattedDate = formattedDate.replace('h00', 'h');
  } catch (err) {
    return '';
  }
  return formattedDate;
};

export const day = (dateValue: string): string => {
  if (!dateValue) {
    return '';
  }
  let formattedDate = '';
  try {
    const parsedDate =
      typeof dateValue === 'object' ? new Date(dateValue) : parseISO(dateValue);


    const zonedDate = utcToZonedTime(parsedDate, 'America/Sao_Paulo');
    formattedDate = format(zonedDate, "dd", { locale: pt });
  } catch (err) {
    return '';
  }
  return formattedDate;
};

export const year = (dateValue: string | Date): string => {
  if (!dateValue) {
    return '';
  }
  let formattedDate = '';
  try {
    const parsedDate =
      typeof dateValue === 'object' ? new Date(dateValue) : parseISO(dateValue);


    const zonedDate = utcToZonedTime(parsedDate, 'America/Sao_Paulo');
    formattedDate = format(zonedDate, "Y", { locale: pt });
  } catch (err) {
    return '';
  }
  return formattedDate;
};

export const monthNumber = (dateValue: string | Date): string => {
  if (!dateValue) {
    return '';
  }
  let formattedDate = '';
  try {
    const parsedDate =
      typeof dateValue === 'object' ? new Date(dateValue) : parseISO(dateValue);


    const zonedDate = utcToZonedTime(parsedDate, 'America/Sao_Paulo');
    formattedDate = format(zonedDate, "MM", { locale: pt });
  } catch (err) {
    return '';
  }
  return formattedDate;
};

export const month = (dateValue: string | Date): string => {
  if (!dateValue) {
    return '';
  }
  let formattedDate = '';
  try {
    const parsedDate =
      typeof dateValue === 'object' ? new Date(dateValue) : parseISO(dateValue);


    const zonedDate = utcToZonedTime(parsedDate, 'America/Sao_Paulo');
    formattedDate = format(zonedDate, "LLL", { locale: pt });
  } catch (err) {
    return '';
  }
  return formattedDate;
};

export const monthFull = (dateValue: string | Date): string => {
  if (!dateValue) {
    return '';
  }
  let formattedDate = '';
  try {
    const parsedDate =
      typeof dateValue === 'object' ? new Date(dateValue) : parseISO(dateValue);


    const zonedDate = utcToZonedTime(parsedDate, 'America/Sao_Paulo');
    formattedDate = format(zonedDate, "LLLL", { locale: pt });
  } catch (err) {
    return '';
  }
  return formattedDate;
};


export const dateLanguageHour = (dateValue: string | Date, language = 'ptBr'): string => {
  if (!dateValue) {
    return '';
  }
  let formattedDate = '';
  try {
    const parsedDate =
      typeof dateValue === 'object' ? new Date(dateValue) : parseISO(dateValue);
    const zonedDate = utcToZonedTime(parsedDate, 'America/Sao_Paulo');

    if (language === 'en') {
      formattedDate = format(zonedDate, "HH'h'mm - ' EEEE", {
        locale: en,
      });
    }
    else if (language === 'es') {
      formattedDate = format(zonedDate, "HH'h'mm '-' EEEE", {
        locale: es,
      });
    }
    else {
      formattedDate = format(zonedDate, "HH'h'mm '-' EEEE", {
        locale: pt,
      });
    }
  } catch (err) {
    return '';
  }



  return formattedDate;
};

export const dateLanguage = (dateValue: string | Date, language = 'ptBr'): string => {
  if (!dateValue) {
    return '';
  }
  let formattedDate = '';
  try {
    const parsedDate =
      typeof dateValue === 'object' ? new Date(dateValue) : parseISO(dateValue);
    const zonedDate = utcToZonedTime(parsedDate, 'America/Sao_Paulo');

    if (language === 'en') {
      formattedDate = format(zonedDate, "MMMM','dd' - ' EEEE", {
        locale: en,
      });
    }
    else if (language === 'es') {
      formattedDate = format(zonedDate, "dd 'de' MMMM '-' EEEE", {
        locale: es,
      });
    }
    else {
      formattedDate = format(zonedDate, "dd 'de' MMMM '-' EEEE", {
        locale: pt,
      });
    }
  } catch (err) {
    return '';
  }

  formattedDate = formattedDate.replace('março', 'Março');
  formattedDate = formattedDate.replace('abril', 'Abril');
  formattedDate = formattedDate.replace('maio', 'Maio');
  formattedDate = formattedDate.replace('junho', 'Junho');
  formattedDate = formattedDate.replace('julho', 'Julho');
  formattedDate = formattedDate.replace('agosto', 'Agosto');
  formattedDate = formattedDate.replace('setembro', 'Setembro');
  formattedDate = formattedDate.replace('outubro', 'Outubro');
  formattedDate = formattedDate.replace('novembro', 'Novembro');
  formattedDate = formattedDate.replace('dezembro', 'Dezembro');
  formattedDate = formattedDate.replace('janeiro', 'Janeiro');
  formattedDate = formattedDate.replace('fevereiro', 'Fevereiro');

  formattedDate = formattedDate.replace('segunda', 'Segunda');
  formattedDate = formattedDate.replace('terça', 'Terça');
  formattedDate = formattedDate.replace('quarta', 'Quarta');
  formattedDate = formattedDate.replace('quinta', 'Quinta');
  formattedDate = formattedDate.replace('sexta', 'Sexta');
  formattedDate = formattedDate.replace('sábado', 'Sábado');
  formattedDate = formattedDate.replace('domingo', 'Domingo');

  return formattedDate;
};
export const simpleDate = (dateValue: string): string => {
  if (!dateValue) {
    return '';
  }
  let formattedDate = '';
  try {
    const parsedDate =
      typeof dateValue === 'object' ? new Date(dateValue) : parseISO(dateValue);
    const zonedDate = utcToZonedTime(parsedDate, 'America/Sao_Paulo');
    formattedDate = format(zonedDate, "dd'.'MM");
  } catch (err) {
    return '';
  }
  return formattedDate;
};

export const simpleDateUS = (dateValue: string): string => {
  if (!dateValue) {
    return '';
  }
  let formattedDate = '';
  try {
    const parsedDate =
      typeof dateValue === 'object' ? new Date(dateValue) : parseISO(dateValue);
    const zonedDate = utcToZonedTime(parsedDate, 'America/Sao_Paulo');
    formattedDate = format(zonedDate, "yyyy'-'MM-dd");
  } catch (err) {
    return '';
  }
  return formattedDate;
};


export const simpleDateBR = (dateValue: string): string => {
  if (!dateValue) {
    return '';
  }
  let formattedDate = '';
  try {
    const parsedDate =
      typeof dateValue === 'object' ? new Date(dateValue) : parseISO(dateValue);
    const zonedDate = utcToZonedTime(parsedDate, 'America/Sao_Paulo');
    formattedDate = format(zonedDate, "dd'/'MM'/'yyyy");
  } catch (err) {
    return '';
  }
  return formattedDate;
};


export const simpleDateBRWithHour = (dateValue: string): string => {
  if (!dateValue) {
    return '';
  }
  let formattedDate = '';
  try {
    const parsedDate =
      typeof dateValue === 'object' ? new Date(dateValue) : parseISO(dateValue);
    const zonedDate = utcToZonedTime(parsedDate, 'America/Sao_Paulo');
    formattedDate = format(zonedDate, "dd'/'MM'/'yyyy HH'h'mm");
  } catch (err) {
    return '';
  }
  return formattedDate;
};

export const simpleDateUSWithHour = (dateValue: string): string => {
  if (!dateValue) {
    return '';
  }
  let formattedDate = '';
  try {
    const parsedDate =
      typeof dateValue === 'object' ? new Date(dateValue) : parseISO(dateValue);
    const zonedDate = utcToZonedTime(parsedDate, 'America/Sao_Paulo');
    formattedDate = format(zonedDate, "yyyy'-'MM-dd HH:mm");
  } catch (err) {
    return '';
  }
  return formattedDate;
};

export const simpleDateBRHour = (dateValue: string): string => {
  if (!dateValue) {
    return '';
  }
  let formattedDate = '';
  try {
    const parsedDate =
      typeof dateValue === 'object' ? new Date(dateValue) : parseISO(dateValue);
    const zonedDate = utcToZonedTime(parsedDate, 'America/Sao_Paulo');
    formattedDate = format(zonedDate, "HH");
  } catch (err) {
    return '';
  }
  return formattedDate;
};
