import id from 'date-fns/esm/locale/id/index.js';
import React from 'react';
import { Link } from "react-router-dom";
import IframePlayerMobile from '../components/IframePlayerMobile';
import IframePlayer from '../components/IframePlayer';
import { PhotoProgramList } from './styles';
import { urlWebsite } from 'src/config';
import { ParagraphStyle } from '../../ContentItem/styles';

interface IProps {
    data?: Record<string, any>;
    computer?: Record<string, any>;
    mobile?: Record<string, any>;
}

const ProgramList: React.FC<IProps> = ({ data, computer, mobile }) => {

    return <> {data?.map(line => <div className=' gap-sm scaleOnHover' style={{ width: '100%', minHeight: '60px', display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>

        {line?.image ? <PhotoProgramList background={`${urlWebsite}/${line?.image}`} border={line?.['border'] || '50%'} width={line?.width || '60px'} height={line?.height || '60px'} /> : <></>}

        <ParagraphStyle computer={computer || {}} mobile={mobile || {}} dangerouslySetInnerHTML={{ __html: line?.text || '<p></p>' }} />



    </div>
    )}</>
}



export default ProgramList;