import React from 'react';

import {
  SearchComponent,
  ISearchComponent,
} from '../../core/components/SearchComponent';

import lineModules from '../../core/components/lineModules'
import { Container } from './styles';
import { useSocket } from '../../../../hooks/Socket';
import { Button } from '../../core/components/Forms/Button/styles';
import { useParams } from 'react-router-dom';

interface ValidationRules {
  type: 'email' | 'required' | 'min' | 'max' | 'length';
  message?: string;
  quantity?: number;
}

interface ValidationSchema {
  name: string;
  type: 'string' | 'number' | 'boolean';
  label: string;
  rules: Array<ValidationRules>;
}

interface ILine {
  [key: string]: string;
}
interface IParams {
  projectHash: string;
  eventHash: string;
}



const Course: React.FC = () => {
  const endpoint = '/courses';
  const title = 'Cursos';
  const formSchema = {
    group_link: {
      model: 'input',
      name: 'group_link',
      label: 'Referência',
      defaultValue: '',
    },
    title: {
      model: 'input',
      name: 'title',
      label: 'Título',
      defaultValue: '',
    },
    type: {
      model: 'selectSimple',
      name: 'type',
      label: 'Tipo',

      options: [
        { label: 'Live', value: 'live' },
        { label: 'On Demand', value: 'ondemand' },


      ]

    },
    size: {
      model: 'selectSimple',
      name: 'size',
      label: 'Orientação',

      options: [
        { label: 'Quadrado', value: 'square' },
        { label: '16:9', value: '16:9' },


      ]

    },
    paid: {
      model: 'selectSimple',
      name: 'paid',
      label: 'Pago?',

      options: [
        { label: 'Pago', value: 'paid' },
        { label: 'Gratuito', value: 'free' },


      ]

    },

    products: {
      model: 'JsonInput',
      bodyRender: (data: Record<string, any>) => (
        <nav>
          <strong>
            (
            {data._id})
            {' '}
            {data.title}
          </strong>

        </nav>
      ),
      searchRender: (data: Record<string, any>) => (
        <>
          <strong>
            (
            {data._id})
            {data.title}
          </strong>

        </>
      ),
      storageType: 'json',
      endpoint: `/products`,
      name: 'products',
      label: 'Produtos',
    },


    lessons: {
      model: 'JsonInput',
      bodyRender: (data: Record<string, any>) => (
        <nav>
          <strong>
            (
            {data.id})
            {' '}
            {data.title}
          </strong>
          <br />
          {data.release_date_formatted || data.release_date}
        </nav>
      ),
      searchRender: (data: Record<string, any>) => (
        <>
          <strong>
            (
            {data.id})
            {data.title}
          </strong>
          <br />
          {data.release_date_formatted || data.release_date}
        </>
      ),
      storageType: 'json',
      endpoint: `/lessons`,
      name: 'lessons',
      label: 'Aulas',
    },
  };


  const formSchemaUpdate = {
    image: {
      model: 'uploadImage',
      type: 'upload',
      name: 'image',
      label: 'Foto',
    },
    ...formSchema
  };
  const formValidation: Record<string, ValidationSchema> = {

  };


  const { projectHash, eventHash } = useParams<IParams>(); const schema: Record<string, any> = {
    _id: { ref: '_id', column: '_id', label: 'ID' },
    group_link: { ref: 'group_link', column: 'group_link', label: 'Referência' },
    title: { ref: 'title', column: 'title', label: 'Título' },
    image: { ref: 'image', column: 'image', label: 'Capa do Curso' },
    lessons: { ref: 'lessons', column: 'lessons', label: 'Aulas', show: false },
    size: { ref: 'size', column: 'size', label: 'Orientação' },
    type: { ref: 'type', column: 'type', label: 'Tipo' },
    paid: { ref: 'paid', column: 'paid', label: 'Pago?' },
    createdAt: {
      ref: 'created_at',
      column: 'createdAt',
      label: 'Data de Criação',
      mask: 'date',
    },
  };

  const config: ISearchComponent = {
    idKey: `${endpoint}-${projectHash}-${eventHash}`,
    title,
    endpoint,
    schema,
    autoStart: true,
    headModules: [
      () =>
        lineModules.create({
          title,
          formSchema,
          validationSchema: formValidation,
          endpoint,
        }),
    ],
    bodyModules: [
      (lineData: Record<string, any>) =>
        lineModules.updateUnique({
          lineData,
          title,
          formSchema: formSchemaUpdate,
          validationSchema: {},
          endpoint,
        }),
      (lineData: Record<string, any>) =>
        lineModules.delete({
          lineData,
          title,
          formSchema,
          validationSchema: formValidation,
          endpoint,
        }),
    ],
  };



  return (
    <Container>

      <SearchComponent {...config} />
    </Container>
  );
};

export default Course;
