import styled from "styled-components";


interface IProps {
    background: string;
    width: string;
    height: string;
    border: string;

}

export const PhotoProgramList = styled.div<IProps>`
display: flex;
justify-content: center;
align-items: center;
cursor: pointer;
border: 2px solid #fff;

flex-direction: row;
flex-wrap: wrap;
min-width: ${props => props.width};
min-height: ${props => props.height};
width: ${props => props.width};
height: ${props => props.height};
 
border-radius: ${props => props?.border};
background : url(${props => props.background}) no-repeat center;
background-size: cover;


transition: 0.5s all;

>p{
opacity: 0;
color: #fff;
font-weight:bold;
font-size: 8px;


text-align: center;
}
 
`;